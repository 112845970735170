import PropTypes from 'prop-types';
import { memo } from 'react';
import { ApolloProvider } from '@apollo/client';

import Routes from '../Routes';
import SplitProvider from '../SplitProvider';

import { createApolloClient } from '~/common/helpers/createApolloClient';
import asyncComponent from '~/components/AsyncComponent';
import { ConfirmDialog } from '~/components/ConfirmDialog';
import { ConfirmDialogProvider } from '~/components/ConfirmDialogV2/contexts/confirmDialogProvider';
import { HeaderProvider } from '~/components/HeaderProvider';
import Loading from '~/components/Loading';
import Snackbar from '~/components/Snackbar';
import ThemeProvider from '~/components/ThemeProvider';
import Toast from '~/components/Toast';
import aiChatPanelConfig from '~/scenes/AI/components/AIChat/AIChat';
import GlobalSidePanel from '~/scenes/AI/components/GlobalSidePanel/GlobalSidePanel';
import Eula from '~/scenes/Governance/scenes/Eula';
import PipelineAccessDenied from '~/scenes/Governance/scenes/PipelineAccessDenied';
import RealmAccessDenied from '~/scenes/Governance/scenes/RealmAccessDenied';

const Profile = asyncComponent(() =>
  import('~/scenes/Governance/scenes/Profile')
);
const NewPassword = asyncComponent(() =>
  import('~/scenes/Governance/scenes/NewPassword')
);
const TwoFactor = asyncComponent(() =>
  import('~/scenes/Governance/scenes/TwoFactor')
);
const FallBackChat = asyncComponent(() =>
  import('~/scenes/Governance/scenes/FallBackChat')
);

const SupportAccess = asyncComponent(() =>
  import('~/scenes/Governance/scenes/SupportAccess')
);

const client = createApolloClient();

const globalSidePanels = [aiChatPanelConfig];

const Application = ({ loading, loadingConnectors, userData, hiddenPages }) => (
  <>
    {loading || (loadingConnectors && !userData) ? (
      <Loading />
    ) : (
      <ApolloProvider client={client}>
        <SplitProvider>
          <ThemeProvider>
            <>
              <Snackbar />
              <ConfirmDialog />
              <ConfirmDialogProvider>
                <Eula />
              </ConfirmDialogProvider>
              <Toast />
              <FallBackChat />
              {userData && (
                <>
                  <PipelineAccessDenied />
                  <RealmAccessDenied />
                  <NewPassword />
                  <TwoFactor />
                  <Profile />
                  <SupportAccess />
                </>
              )}
              <HeaderProvider>
                <GlobalSidePanel panels={globalSidePanels}>
                  <Routes hiddenPages={hiddenPages} />
                </GlobalSidePanel>
              </HeaderProvider>
            </>
          </ThemeProvider>
        </SplitProvider>
      </ApolloProvider>
    )}
  </>
);

Application.propTypes = {
  loadingConnectors: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  userData: PropTypes.objectOf({}).isRequired,
  hiddenPages: PropTypes.arrayOf(PropTypes.string).isRequired
};
export default memo(Application);
