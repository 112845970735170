import { Button, Dialog, Input, Text } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Elements from './ConfirmDialog.elements';
import useConfirmDialog from './hooks/useConfirmDialog';
import { ConfirmDialogConfig } from './type/ConfirmDialog';
import { MenuTag } from '../MenuTag';

export type ConfirmDialogProps = ConfirmDialogConfig & {
  open: boolean;
  onCancel: () => void;
};

const ConfirmDialog = ({
  open,
  onCancel,
  cancelText,
  confirmText,
  content,
  danger,
  onConfirm,
  title,
  withExplanation,
  explanationPlaceholder,
  explanationLabel,
  hideCancel,
  explanationShouldValidate,
  showEnvironmentTag,
  ...rest
}: ConfirmDialogProps) => {
  const [explanation, setExplanation] = useState<string>('');

  const { t } = useTranslation();

  const buttonVariant = danger ? 'danger' : 'primary';

  function handleClick() {
    onConfirm(explanation);
    setExplanation('');
  }

  function handleKeyPress(keyCode: string) {
    const isDisabled = withExplanation
      ? explanationShouldValidate
        ? explanationShouldValidate(explanation)
        : !explanation
      : false;

    if (!isDisabled) {
      if (keyCode === 'Enter') handleClick();
    }
  }

  function handleChange(value?: string) {
    setExplanation(value || '');
  }

  return (
    <Dialog.Root open={open}>
      <Dialog.Content data-testid={rest['data-testid']}>
        <Elements.Container>
          {showEnvironmentTag && <MenuTag />}
          <Text css={{ wordBreak: 'break-all' }} as='h1' variant='h4'>
            {title}
          </Text>
          <Dialog.Body>
            {choose(
              when(typeof content === 'string', () => (
                <Text as='p' variant='b2'>
                  {content}
                </Text>
              )),
              otherwise(() => <>{content}</>)
            )}
            {iff(Boolean(withExplanation), () => (
              <Input
                label={explanationLabel || t('label.explanation')}
                placeholder={explanationPlaceholder}
                value={explanation}
                onChange={ev => handleChange(ev?.target.value)}
                onKeyDown={key => handleKeyPress(key.code)}
                data-testid='confirm-dialog-explanation-input'
              />
            ))}
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              size='medium'
              variant={buttonVariant}
              onClick={() => handleClick()}
              // eslint-disable-next-line no-nested-ternary
              disabled={
                withExplanation
                  ? explanationShouldValidate
                    ? explanationShouldValidate(explanation)
                    : !explanation
                  : false
              }
              data-testid='confirm-dialog-confirm-button'
            >
              {confirmText || t('action.confirm')}
            </Button>
            {!hideCancel && (
              <Dialog.Close asChild>
                <Button
                  outlined
                  size='medium'
                  variant={buttonVariant}
                  onClick={() => onCancel()}
                  data-testid='confirm-dialog-cancel-button'
                >
                  {cancelText || t('action.cancel')}
                </Button>
              </Dialog.Close>
            )}
          </Dialog.Footer>
        </Elements.Container>
      </Dialog.Content>
    </Dialog.Root>
  );
};

ConfirmDialog.useConfirmDialog = 'useConfirmDialog';

export default Object.assign(ConfirmDialog, {
  useConfirmDialog
});
