import { Box, Flex, Icon, IconButton, Menu } from '@digibee/beehive-ui';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import useEnvironments from '~/common/hooks/useEnvironments';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export const ENV_MENU_COLORS = [
  'pri300',
  'ora300',
  'blu300',
  'pri600',
  'ora600',
  'blu600'
];

type MenuSelectProps = {
  type?: 'application' | 'apiKeys';
};

export const MenuSelect = ({ type = 'application' }: MenuSelectProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { application, apiKeys } = useSelector<
    StoreRootState,
    Pick<StoreRootState, 'application' | 'apiKeys'>
  >(state => state);
  const dispatch = useDispatch();
  const [filteredEnvironments] = useEnvironments('APIKEY:READ');
  const valuesByType = useMemo(
    () => ({
      application: {
        environments: application.environments?.allNames?.asMutable({
          deep: true
        }),
        environment: application.environment
      },
      apiKeys: {
        environments: filteredEnvironments.map(
          (env: { name: string }) => env.name
        ) as string[],
        environment: apiKeys.environment
      }
    }),
    [
      apiKeys.environment,
      application.environment,
      application.environments?.allNames,
      filteredEnvironments
    ]
  );

  const changeEnvironment = (value: string) => {
    if (type === 'apiKeys') {
      dispatch.apiKeys.setEnvironment(value);
      return;
    }

    const pathParts = location.pathname.split('/');

    const envIndex = pathParts.findIndex(part =>
      valuesByType[type].environments?.includes(part)
    );

    if (envIndex < 0) {
      return;
    }

    pathParts[envIndex] = value;
    const newPath = pathParts.join('/');
    const searchParams = new URLSearchParams(location.search);
    const newUrl = `${newPath}?${searchParams.toString()}`;
    navigate(newUrl, { replace: true });
  };

  const sortedEnvs = useMemo(() => {
    if (!valuesByType[type].environments.length) return [];

    const envNames = valuesByType[type].environments;

    const prodIndex = envNames.indexOf('prod');
    if (prodIndex > -1) {
      envNames.splice(prodIndex, 1);
      envNames.unshift('prod');
    }
    const testIndex = envNames.indexOf('test');
    if (testIndex > -1) {
      envNames.splice(testIndex, 1);
      envNames.splice(1, 0, 'test');
    }
    return envNames;
  }, [type, valuesByType]);

  const selectedEnv = {
    idx: sortedEnvs.findIndex(env => env === valuesByType[type].environment),
    name: valuesByType[type].environment
  };

  return (
    <Menu.Root>
      <Menu.Content
        css={{
          width: '168px'
        }}
        componentTrigger={
          <IconButton
            css={{
              display: 'flex',
              padding: '0 8px 0 4px',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '20px',
              border: '1px solid var(--colors-neu300)',
              background: 'white',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 400,
              color: 'var(--colors-neu500)',
              textTransform: 'capitalize',
              marginRight: '24px',
              fontFamily: 'var(--fonts-display)'
            }}
            type='button'
          >
            <Box
              css={{
                width: '16px',
                height: '16px',
                borderRadius: '100%',
                background: `var(--colors-${ENV_MENU_COLORS[selectedEnv.idx]})`
              }}
            />
            {selectedEnv.name}
            <Flex
              css={{
                width: '23px',
                height: '22px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon icon='angle-down' variant='secondary' size='md' />
            </Flex>
          </IconButton>
        }
      >
        {sortedEnvs.map((env, index) => (
          <Menu.Item
            size='large'
            css={{
              gap: '8px',
              color: 'var(--colors-neu600)',
              fontSize: '14px',
              textTransform: 'capitalize',
              paddingLeft: '28px'
            }}
            onClick={() => changeEnvironment(env)}
            key={env}
          >
            <Box
              css={{
                width: '13px',
                height: '13px',
                borderRadius: '100%',
                background: `var(--colors-${ENV_MENU_COLORS[index]})`
              }}
            />
            {env}
          </Menu.Item>
        ))}
      </Menu.Content>
    </Menu.Root>
  );
};
