import { Box, Flex } from '@digibee/beehive-ui';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StoreRootState } from '~/scenes/Monitor/types/StoreRootState';
import { ENV_MENU_COLORS } from '~/scenes/Others/scenes/App/Operation/components/Header/components/MenuSelect';

export const MenuTag = () => {
  const { application } = useSelector<
    StoreRootState,
    Pick<StoreRootState, 'application'>
  >(state => state);

  const { environment } = application;
  const environments = application.environments?.allNames?.asMutable({
    deep: true
  });

  const sortedEnvs = useMemo(() => {
    if (!environments.length) return [];

    const envNames = environments;

    const prodIndex = envNames.indexOf('prod');
    if (prodIndex > -1) {
      envNames.splice(prodIndex, 1);
      envNames.unshift('prod');
    }
    const testIndex = envNames.indexOf('test');
    if (testIndex > -1) {
      envNames.splice(testIndex, 1);
      envNames.splice(1, 0, 'test');
    }
    return envNames;
  }, [environments]);

  const selectedEnv = {
    idx: sortedEnvs.findIndex(env => env === environment),
    name: environment
  };

  return (
    <Flex
      css={{
        display: 'flex',
        padding: '1px 7px 1px 3px',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '20px',
        border: '1px solid var(--colors-neu300)',
        background: 'white',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        color: 'var(--colors-neu500)',
        textTransform: 'capitalize',
        fontFamily: 'var(--fonts-display)',
        width: 'fit-content'
      }}
    >
      <Box
        css={{
          width: '16px',
          height: '16px',
          borderRadius: '100%',
          background: `var(--colors-${ENV_MENU_COLORS[selectedEnv.idx]})`
        }}
      />
      {selectedEnv.name}
    </Flex>
  );
};
