import { createRoot } from 'react-dom/client';
import 'reset-css/reset.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from '~/scenes/Others/scenes/App';

import '~/common/helpers/i18n';

// import { inspect } from '@xstate/inspect';

// // Habilite o DevTools para integração com o Visualizer
// if (process.env.NODE_ENV === 'development') {
//   inspect({
//     // Endereço do servidor remoto do Visualizer
//     iframe: false // Usa uma janela separada ao invés de um iframe
//   });
// }
async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  // eslint-disable-next-line consistent-return
  return worker.start();
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

enableMocking().then(() => {
  root.render(<App />);
});
