import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

import i18n from '~/common/helpers/i18n';
import TopBar from '~/components/TopBar';

const Container = styled.div`
  height: calc(100vh - 32px);
`;

const Content = styled.div`
  height: 100%;
  display: flex;
`;

const Body = styled.div`
  width: 100%;
  overflow: hidden;
`;

const WrapperSwitch = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 112px);
  overflow: auto;
`;

const Settings = ({
  containPaths,
  onPressBack,
  realm,
  realmData,
  license,
  hasCustomerIdentityProvider,
  showLimitScreen,
  showEnablePolicyManagementSystem,
  showTogaiRedirectButton,
  togaiToken
}) => (
  <Container>
    <TopBar
      onBack={onPressBack}
      linkText={i18n.t('scenes.beta.actions.back_to_pipelines')}
    />
    <Content>
      <div>
        <Sidebar
          containPaths={containPaths}
          realm={realm}
          realmData={realmData}
          license={license}
          showLimitScreen={showLimitScreen}
          hasCustomerIdentityProvider={hasCustomerIdentityProvider}
          showEnablePolicyManagementSystem={showEnablePolicyManagementSystem}
          togaiToken={togaiToken}
          showTogaiRedirectButton={showTogaiRedirectButton}
        />
      </div>
      <Body>
        <Header license={license} />
        <WrapperSwitch>
          <Outlet />
        </WrapperSwitch>
      </Body>
    </Content>
  </Container>
);

Settings.defaultProps = {
  containPaths: () => {},
  onPressBack: () => {},
  togaiToken: null
};

Settings.propTypes = {
  containPaths: PropTypes.func,
  onPressBack: PropTypes.func,
  realm: PropTypes.string.isRequired,
  realmData: PropTypes.objectOf({}).isRequired,
  license: PropTypes.objectOf({}).isRequired,
  hasCustomerIdentityProvider: PropTypes.string.isRequired,
  showLimitScreen: PropTypes.bool.isRequired,
  showEnablePolicyManagementSystem: PropTypes.bool.isRequired,
  showTogaiRedirectButton: PropTypes.bool.isRequired,
  togaiToken: PropTypes.string
};

export default Settings;
