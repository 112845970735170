import { useSelector } from 'react-redux';

/**
 * Returns a list of environments filtered off service acl.
 *
 * @returns [enviroments]
 */
function useEnvironments(acl) {
  const scopes = useSelector(state => state.acls?.scopes);
  const environments = useSelector(state => state.application?.environments?.allNames?.asMutable?.({deep: true}));
  const environmentsData = useSelector(state => state.application?.environments?.byName?.asMutable?.({deep: true}));

  const defaultAcls = ['GROUP:ADMIN'];

  const containScopes = scopeValue => {
    if (!scopeValue) return false;
    const scopeArray = Array.isArray(scopeValue) ? scopeValue : [scopeValue];
    const roles = [].concat(scopeArray, defaultAcls);

    return scopes.some(scope => roles.includes(scope));
  };

  if(containScopes(acl)) return [environments.map(env => environmentsData[env])]

  let filterdEnviroments = [];

  scopes?.filter(scope => scope?.split('{ENV')[0] === acl).map(scope => {
    const env = scope?.split('{ENV=')[1]?.replace('}', '');
      if(env) {
        filterdEnviroments.push(environmentsData[env?.toLowerCase()]);
      }
   })

  return [filterdEnviroments];
}

export default useEnvironments;
