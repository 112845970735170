import { Box, Button, Dialog, Text } from '@digibee/beehive-ui';
import React from 'react';
import { useSelector } from 'react-redux';

import * as Elements from './ConfirmPromote.elements';
import DeployPipeline from '../DeployPipeline/components/DeployPipelineDialog/DeployPipeline';

import i18n from '~/common/helpers/i18n';
import { MenuTag } from '~/components/MenuTag';
import { Deployment } from '~/entities/Deployment';

interface RootState {
  authentication: {
    userData: {
      email: string;
    };
  };
}

type Props = {
  onClose: () => void;
  loadingPromote: boolean;
  confirmDeploy: (value: any) => void;
  dataModal: {
    deployment: Deployment;
    texts: {
      title: string;
      text: string;
      confirmText: string;
    };
    isRedeploy: boolean;
  };
  oldDeployment: Deployment;
  isDeletePipe: boolean;
  pipelineToPromoteConfirm: Function;
  triggers: Record<string, any>;
};

const ConfirmPromoteV2 = ({
  onClose,
  dataModal,
  oldDeployment,
  isDeletePipe,
  loadingPromote,
  pipelineToPromoteConfirm,
  triggers
}: Props) => {
  const oldEnviroment = oldDeployment?.activeConfiguration?.environment?.name;
  const enviroment = dataModal?.deployment.newEnvironment;
  const width = isDeletePipe ? '40rem' : '80rem';

  const userData = useSelector(
    (state: RootState) => state.authentication?.userData
  );

  const updatedDeployment = {
    ...dataModal.deployment,
    metadata: {
      userEmail: userData?.email,
      userName: userData?.email
        ?.split('@')[0]
        ?.split('.')
        .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ')
    }
  };

  return (
    <>
      <Dialog.Root open onOpenChange={onClose}>
        <React.Fragment key='.0'>
          <Dialog.Content
            style={{
              width,
              maxWidth: 'none',
              maxHeight: '98vh'
            }}
          >
            <Dialog.Body css={{ overflow: 'auto', maxHeight: '90vh' }}>
              <Box css={{ marginTop: '32px', marginLeft: '16px' }}>
                <MenuTag />
              </Box>
              <Box css={{ margin: '$3' }}>
                <Text as='h1' variant='h4'>
                  {i18n.t(`label.move_pipeline_to_${enviroment}_prompt`, {
                    pipelineName: dataModal.deployment?.pipeline.name
                  })}
                </Text>
                <Text>
                  {i18n.t(
                    `${
                      isDeletePipe
                        ? 'label.promote_pipe_and_delete_from_previous_env_msg'
                        : 'label.promote_pipe_and_keep_in_previous_env_msg'
                    }`
                  )}
                </Text>
                {isDeletePipe ? (
                  <DeployPipeline
                    environment={dataModal.deployment.environment || ''}
                    // @ts-ignore
                    deployment={dataModal.deployment}
                    isRedeploy={dataModal.isRedeploy}
                    triggers={triggers}
                  />
                ) : (
                  <Elements.ContainerPromote>
                    <Elements.BoxPromote>
                      <Text as='h1' variant='h4'>
                        {i18n.t('label.env_name_as_variable_environment', {
                          environmentName: oldEnviroment
                        })}
                      </Text>
                      <DeployPipeline
                        // @ts-ignore
                        deployment={oldDeployment}
                        environment={
                          oldDeployment?.activeConfiguration?.environment
                            ?.name || ''
                        }
                        folderPathView={false}
                        triggers={triggers}
                      />
                    </Elements.BoxPromote>
                    <Elements.BoxPromote>
                      <Text as='h1' variant='h4'>
                        {i18n.t('label.env_name_as_variable_environment', {
                          environmentName: enviroment
                        })}
                      </Text>
                      <DeployPipeline
                        // @ts-ignore
                        deployment={updatedDeployment}
                        triggers={triggers}
                        environment={dataModal.deployment.newEnvironment || ''}
                        folderPathView={false}
                      />
                    </Elements.BoxPromote>
                  </Elements.ContainerPromote>
                )}
              </Box>
            </Dialog.Body>
            <Dialog.Footer
              css={{
                margin: '$3'
              }}
            >
              <Button
                size='medium'
                variant='primary'
                onClick={() =>
                  pipelineToPromoteConfirm({
                    ...dataModal.deployment,
                    newEnvironment: dataModal.deployment.newEnvironment
                  })
                }
                loading={loadingPromote}
                data-testid={`run-confirm-promote-${dataModal.deployment.newEnvironment}`}
              >
                {i18n.t('action.promote')}
              </Button>
              <Dialog.Close asChild>
                <Button
                  outlined
                  size='medium'
                  variant='primary'
                  data-testid={`run-cancel-promote-${dataModal.deployment.newEnvironment}`}
                >
                  {i18n.t('action.cancel')}
                </Button>
              </Dialog.Close>
            </Dialog.Footer>
          </Dialog.Content>
        </React.Fragment>
      </Dialog.Root>
    </>
  );
};

export default ConfirmPromoteV2;
